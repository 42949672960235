
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { PurchaseOrder } from '@/entities/purchase'

@Component
  export default class AlertConsignment extends Cell {
  alert = {
    background: '',
    color: '',
    icon: '',
    tooltip: '',
    value: 0,
  }

  async mounted () {
    const { item } = this

    if (!item?.id || (item?.status?.name !== 'pickup_active' && item?.status?.name !== 'pickup_to_update')) return

    if (item instanceof PurchaseOrder) {
      await this.getInfo()
    }
  }

  async getInfo () {
    const { item, fetchData } = this

    const idDeal = item.negotiation.inspection.deal.id
    const payments = await fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_payment_recipient: { _is_null: true } },
          { deal: { id: { _eq: idDeal } } },
        ],
      },
      force: true,
    })

    if (!payments?.length) {
      return
    }

    const pending = payments.some(payment => payment?.status?.isPending)
    const approved = payments.every(payment => payment?.closingReason?.successful)
    const updating = payments.some(payment => payment?.status?.isToUpdating)
    if (updating) {
      this.setToUpdating()
    } else if (pending) {
      this.setPending()
    } else if (approved) {
      this.setAlert()
    }
  }

  setToUpdating () {
    this.alert = {
      background: 'white',
      color: 'red',
      icon: 'mdi-cash',
      tooltip: 'Pagos por actualizar',
      value: 0,
    }
  }

  setPending () {
    this.alert = {
      background: 'white',
      color: 'orange',
      icon: 'mdi-cash',
      tooltip: 'Pagos por validar',
      value: 0,
    }
  }

  setAlert () {
    this.alert = {
      background: 'white',
      color: 'green',
      icon: 'mdi-cash',
      tooltip: 'Pagos validados',
      value: 0,
    }
  }

  get background () {
    const { alert: { background } } = this
    return background
  }

  get color () {
    const { alert: { color } } = this
    return color
  }

  get icon () {
    const { alert: { icon } } = this
    return icon
  }

  get tooltip () {
    const { alert: { tooltip } } = this
    return tooltip
  }

  get value () {
    const { alert: { value = 0 } } = this
    return value
  }
  }
